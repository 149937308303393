/* eslint-disable linebreak-style */
import home from '../view/page/home';
// import pageAbout from '../views/pages/about';
// import pageDetail from '../views/pages/detail';
// import pageFavorite from '../views/pages/favorite';

const routes = {
  '/': home, // default page
  '/home': home,
  // '/favorite': pageFavorite,
  // '/my': pageAbout,
  // '/detail/:id': pageDetail,
};

export default routes;
